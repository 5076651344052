import React from "react";

const NotificationHeader = () => {
	return (
		<div className="notification-header">
			<p>Notifications</p>
		</div>
	);
};

export default NotificationHeader;
