import React from "react";

const NotificationFooter = () => {
	return (
		<div className="notification-footer">
			<a>All Notifications</a>
		</div>
	);
};

export default NotificationFooter;
