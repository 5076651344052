export const industries = [
	{ value: "advertising", label: "Advertising" },
	{ value: "agtech", label: "AgTech" },
	{ value: "ai", label: "AI" },
	{ value: "analytics", label: "Analytics" },
	{ value: "ar_vr", label: "AR/VR" },
	{ value: "audiotech", label: "AudioTech" },
	{ value: "autotech", label: "AutoTech" },
	{ value: "biotech", label: "BioTech" },
	{ value: "blockchain", label: "Blockchain" },
	{ value: "cannabis", label: "Cannabis" },
	{ value: "chemicals", label: "Chemicals" },
	{ value: "cleantech_environment", label: "CleanTech/Environment" },
	{ value: "cloud_infrastructure", label: "Cloud Infrastructure" },
	{ value: "constructiontech", label: "ConstructionTech" },
	{ value: "consumer_health", label: "Consumer Health" },
	{ value: "consumer_internet", label: "Consumer Internet" },
	{ value: "cosmetics", label: "Cosmetics" },
	{ value: "cryptocurrency", label: "Cryptocurrency" },
	{ value: "cybersecurity", label: "Cybersecurity" },
	{ value: "data_services", label: "Data Services" },
	{ value: "developer_tools", label: "Developer Tools" },
	{ value: "diagnostics", label: "Diagnostics" },
	{ value: "digital_health", label: "Digital Health" },
	{ value: "direct_to_consumer", label: "Direct-to-Consumer (DTC)" },
	{ value: "drug_delivery", label: "Drug Delivery" },
	{ value: "e_commerce", label: "E-commerce" },
	{ value: "education", label: "Education" },
	{ value: "energytech", label: "EnergyTech" },
	{ value: "enterprise_applications", label: "Enterprise Applications" },
	{ value: "enterprise_infrastructure", label: "Enterprise Infrastructure" },
	{ value: "enterprise", label: "Enterprise" },
	{ value: "entertainment_sports", label: "Entertainment & Sports" },
	{ value: "fashion", label: "Fashion" },
	{ value: "fintech", label: "FinTech" },
	{ value: "food_beverage", label: "Food and Beverage" },
	{ value: "future_of_work", label: "Future of Work" },
	{ value: "games", label: "Games" },
	{ value: "gaming_esports", label: "Gaming/eSports" },
	{ value: "gig_economy", label: "Gig Economy" },
	{ value: "govtech", label: "GovTech" },
	{ value: "hardware", label: "Hardware" },
	{ value: "health_hospital_services", label: "Health & Hospital Services" },
	{ value: "health_it", label: "Health IT" },
	{ value: "human_capital_hrtech", label: "Human Capital/HRTech" },
	{ value: "impact", label: "Impact" },
	{ value: "insurance", label: "Insurance" },
	{ value: "iot", label: "IoT" },
	{ value: "legaltech", label: "LegalTech" },
	{ value: "local_services", label: "Local Services" },
	{ value: "lodging_hospitality", label: "Lodging/Hospitality" },
	{ value: "logistics", label: "Logistics" },
	{ value: "manufacturing", label: "Manufacturing" },
	{ value: "marketingtech", label: "MarketingTech" },
	{ value: "marketplaces", label: "Marketplaces" },
	{ value: "material_science", label: "Material Science" },
	{ value: "media_content", label: "Media/Content" },
	{ value: "medical_devices", label: "Medical Devices" },
	{ value: "messaging", label: "Messaging" },
	{ value: "mobility", label: "Mobility" },
	{ value: "parenting_families", label: "Parenting/Families" },
	{ value: "payments", label: "Payments" },
	{ value: "pharmaceuticals", label: "Pharmaceuticals" },
	{ value: "real_estate_proptech", label: "Real Estate/PropTech" },
	{ value: "retail", label: "Retail" },
	{ value: "robotics", label: "Robotics" },
	{ value: "saas", label: "SaaS" },
	{ value: "sales_crm", label: "Sales & CRM" },
	{ value: "security", label: "Security" },
	{ value: "semiconductors", label: "Semiconductors" },
	{ value: "smb_software", label: "SMB Software" },
	{ value: "social_commerce", label: "Social Commerce" },
	{ value: "social_networks", label: "Social Networks" },
	{ value: "space", label: "Space" },
	{ value: "transportationtech", label: "TransportationTech" },
	{ value: "travel", label: "Travel" },
	{ value: "wellness_fitness", label: "Wellness & Fitness" },
];

export const demographic = [
	{ value: "female", label: "Female" },
	{ value: "hispanic_latinx", label: "Hispanic or Latinx" },
	{ value: "black_african-american", label: "Black or African-American" },
	{ value: "asian", label: "Asian" },
	{ value: "american_indian_alaska_native", label: "American Indian or Alaska Native" },
	{ value: "middle_eastern_north_african", label: "Middle Eastern or North African" },
	{ value: "native_hawaiian_other_pacific_islander", label: "Native Hawaiian or Other Pacific Islander" },
	{ value: "white", label: "White" },
	{ value: "lgbtq", label: "LGBTQ+" },
	{ value: "veteran", label: "Veteran" },
	{ value: "immigrant", label: "Immigrant" },
	{ value: "disability", label: "Having a Disability" },
	{ value: "1st_generation_college_student", label: "1st Generation College Student" },
	{ value: "other", label: "Other" },
	{ value: "all", label: "All - No specific demographic" },
];

export const investment_description = [
	{ value: "angel", label: "Angel" },
	{ value: "vc_early stage", label: "VC - Early Stage" },
	{ value: "vc_growth stage", label: "VC - Growth Stage" },
	{ value: "vc_corporate", label: "VC - Corporate" },
	{ value: "university", label: "University" },
	{ value: "government", label: "Government" },
	{ value: "accelerator", label: "Accelerator" },
	{ value: "incubator", label: "Incubator" },
	{ value: "venture_studio", label: "Venture Studio" },
	{ value: "limited_partner", label: "Limited Partner" },
	{ value: "other", label: "Other" },
	{ value: "aspiring_angel", label: "Aspiring Angel" },
	{ value: "family_office", label: "Family Office" },
];

export const fundraising_stage = [
	{ value: "pre-seed", label: "Pre-Seed" },
	{ value: "seed", label: "Seed" },
	{ value: "series_a", label: "Series A" },
	{ value: "series_b", label: "Series B" },
	{ value: "series_c", label: "Series C" },
];
